import Image from 'next/image';
import Link from 'next/link';
import FooterData from '../../data/footer.json';
import logo from '../../public/images/logo/logo.png';
import CopyRight from './CopyRight';

const FooterOne = ({ bgColor }) => {
  return (
    <>
      <footer
        className={`rbt-footer footer-style-1 ${
          bgColor ? bgColor : 'bg-color-white'
        } overflow-hidden`}
      >
        <div className="footer-top">
          <div className="container">
            {FooterData &&
              FooterData.footerTwo.map((footer, index) => (
                <div className="row g-5" key={index}>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="footer-widget">
                      <div className="logo">
                        <Link href="/">
                          <Image
                            src={logo}
                            width={50}
                            height={50}
                            alt="Indian Chess Academy Logo"
                          />
                        </Link>
                      </div>

                      <p className="description mt--20">Indian Chess Academy</p>

                      <ul className="social-icon social-default justify-content-start">
                        {footer.socialLink.map((value, innerIndex) => (
                          <li key={innerIndex}>
                            <Link href={value.link}>
                              <i className={value.icon}></i>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="footer-widget">
                      <h5 className="ft-title"> Contact Us</h5>
                      <ul className="ft-link">
                        <li>
                          <span>Phone: </span>{' '}
                          <Link href="tel:+918560085700">+91 85600 85700</Link>
                        </li>
                        <li>
                          <span>E-mail:</span>{' '}
                          <Link href="mailto:pkpsgnr@gmail.com">
                            pkpsgnr@gmail.com
                          </Link>
                        </li>
                        <li>
                          <span>WhatsApp:</span>{' '}
                          <Link href="https://wa.me/918560085700">
                            Click to Chat
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <CopyRight />
      </footer>
    </>
  );
};

export default FooterOne;
